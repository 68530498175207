@import "bootstrap/dist/css/bootstrap.min.css";
@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@300@400;500;700&display=swap");

body {
  background-color: #e3e3e3;
  font-family: "Roboto", sans-serif;
  font-size: 14px;
  color: #26324b;
}

img {
  max-width: 100%;
}

ul {
  margin: 0;
  padding: 0;
  list-style: none;
}

a {
  text-decoration: none;
}

input.form-control {
  background: transparent;
  color: #e3e3e3;
  border-radius: 0;
  border-color: #c9a145;
  font-size: 14px;
  font-weight: 300;
}

input:focus,
input.form-control:focus {
  background: transparent;
  box-shadow: none !important;
}

.btn-primary {
  background: #c9a145;
  border-color: #c9a145;
  font-size: 13px;
  border-radius: 50px;
  padding: 6px 25px;
}

.btn-primary:hover,
.btn-primary:focus {
  background: #c9a145 !important;
  border-color: #c9a145 !important;
}

.btn-outline-primary {
  background: transparent !important;
  border-color: #c9a145;
}

.btn-outline-primary:hover,
.btn-outline-primary:focus {
  background: transparent !important;
  border-color: #c9a145 !important;
}

.btn-outline-secondary {
  font-size: 11px;
  border-radius: 50px;
  padding: 6px 25px;
}

.main
  .TopCategories
  .nav-tabs
  .nav-item
  .nav-link#uncontrolled-tab-example-tab-Roulette {
  background-image: url("https://gbet567.com/images/Roulette_Icons.png");
  background-repeat: repeat;
  background-size: auto;
  color: #fff;
  font-size: 14px;
  background-repeat: no-repeat;
  background-repeat: no-repeat;
  background-position: 27px 10px;
  background-size: 30px 30px;
  padding: 46px 5px 5px 5px;
  width: 92px;
  height: 100%;
  border: 0;
  border-radius: 0px !important;
}

.main
  .TopCategories
  .nav-tabs
  .nav-item
  #uncontrolled-tab-example-tab-Baccarat {
  background-image: url("https://gbet567.com/images/Baccarat_Icons.png");
  background-repeat: repeat;
  background-size: auto;
  color: #fff;
  font-size: 14px;
  background-repeat: no-repeat;
  background-repeat: no-repeat;
  background-position: 27px 10px;
  background-size: 30px 30px;
  padding: 46px 5px 5px 5px;
  width: 92px;
  height: 100%;
  border: 0;
  border-radius: 0px !important;
}

.main
  .TopCategories
  .nav-tabs
  .nav-item
  #uncontrolled-tab-example-tab-Andar\ Bahar {
  background-image: url("https://gbet567.com/images/Andar_bahar_Icons.png");
  background-repeat: repeat;
  background-size: auto;
  color: #fff;
  font-size: 14px;
  background-repeat: no-repeat;
  background-repeat: no-repeat;
  background-position: 27px 10px;
  background-size: 30px 30px;
  padding: 46px 5px 5px 5px;
  width: 92px;
  height: 100%;
  border: 0;
  border-radius: 0px !important;
}

.main .TopCategories .nav-tabs .nav-item #uncontrolled-tab-example-tab-Poker {
  background-image: url("https://gbet567.com/images/Poker_Icons.png");
  background-repeat: repeat;
  background-size: auto;
  color: #fff;
  font-size: 14px;
  background-repeat: no-repeat;
  background-repeat: no-repeat;
  background-position: 27px 10px;
  background-size: 30px 30px;
  padding: 46px 5px 5px 5px;
  width: 92px;
  height: 100%;
  border: 0;
  border-radius: 0px !important;
}

.main
  .TopCategories
  .nav-tabs
  .nav-item
  #uncontrolled-tab-example-tab-Blackjack {
  background-image: url("https://gbet567.com/images/Blackjack_icons.png");
  background-repeat: repeat;
  background-size: auto;
  color: #fff;
  font-size: 14px;
  background-repeat: no-repeat;
  background-repeat: no-repeat;
  background-position: 27px 10px;
  background-size: 30px 30px;
  padding: 46px 5px 5px 5px;
  width: 92px;
  height: 100%;
  border: 0;
  border-radius: 0px !important;
}

.main
  .TopCategories
  .nav-tabs
  .nav-item
  #uncontrolled-tab-example-tab-TeenPatti {
  background-image: url("https://gbet567.com/images/Teenpatti.png");
  background-repeat: repeat;
  background-size: auto;
  color: #fff;
  font-size: 14px;
  background-repeat: no-repeat;
  background-repeat: no-repeat;
  background-position: 27px 10px;
  background-size: 30px 30px;
  padding: 46px 5px 5px 5px;
  width: 92px;
  height: 100%;
  border: 0;
  border-radius: 0px !important;
}

.main .TopCategories .nav-tabs .nav-item #uncontrolled-tab-example-tab-32Card {
  background-image: url("https://gbet567.com/images/casino.png");

  background-repeat: repeat;
  background-size: auto;
  color: #fff;
  font-size: 14px;
  background-repeat: no-repeat;
  background-repeat: no-repeat;
  background-position: 27px 10px;
  background-size: 30px 30px;
  padding: 46px 5px 5px 5px;
  width: 92px;
  height: 100%;
  border: 0;
  border-radius: 0px !important;
}

.main .TopCategories .nav-tabs .nav-item #uncontrolled-tab-example-tab-Seven {
  background-image: url("https://gbet567.com/images/seven.png");

  background-repeat: repeat;
  background-size: auto;
  color: #fff;
  font-size: 14px;
  background-repeat: no-repeat;
  background-repeat: no-repeat;
  background-position: 27px 10px;
  background-size: 30px 30px;
  padding: 46px 5px 5px 5px;
  width: 92px;
  height: 100%;
  border: 0;
  border-radius: 0px !important;
}

.main .TopCategories .nav-tabs .nav-item #uncontrolled-tab-example-tab-Dices {
  background-image: url("https://gbet567.com/images/dices.png");

  background-repeat: repeat;
  background-size: auto;
  color: #fff;
  font-size: 14px;
  background-repeat: no-repeat;
  background-repeat: no-repeat;
  background-position: 27px 10px;
  background-size: 30px 30px;
  padding: 46px 5px 5px 5px;
  width: 92px;
  height: 100%;
  border: 0;
  border-radius: 0px !important;
}
.main .TopCategories .nav-tabs .nav-item #uncontrolled-tab-example-tab-Game {
  background-image: url("https://gbet567.com/images/sports.png");

  background-repeat: repeat;
  background-size: auto;
  color: #fff;
  font-size: 14px;
  background-repeat: no-repeat;
  background-repeat: no-repeat;
  background-position: 27px 10px;
  background-size: 30px 30px;
  padding: 46px 5px 5px 5px;
  width: 100px;
  height: 100%;
  border: 0;
  border-radius: 0px !important;
}
.main .TopCategories .nav-tabs .nav-item #uncontrolled-tab-example-tab-Sports {
  background-image: url("https://gbet567.com/images/sports.png");

  background-repeat: repeat;
  background-size: auto;
  color: #fff;
  font-size: 14px;
  background-repeat: no-repeat;
  background-repeat: no-repeat;
  background-position: 27px 10px;
  background-size: 30px 30px;
  padding: 46px 5px 5px 5px;
  width: 92px;
  height: 100%;
  border: 0;
  border-radius: 0px !important;
}

.main
  .TopCategories
  .nav-tabs
  .nav-item
  #uncontrolled-tab-example-tab-providers {
  background-image: url("./assets/images/providericon.png");

  background-repeat: no-repeat;
  background-size: auto;
  color: #fff;
  font-size: 14px;
  background-position: 27px 10px;
  background-size: 30px 30px;
  padding: 46px 5px 5px 5px;
  width: 92px;
  height: 100%;
  border: 0;
  border-radius: 0px !important;
}
.main .TopCategories .nav-tabs .nav-item #uncontrolled-tab-example-tab-Spribe {
  background-image: url("./assets/images/icons/airplane-mode.png");

  background-repeat: no-repeat;
  background-size: auto;
  color: #fff;
  font-size: 14px;
  background-position: 27px 10px;
  background-size: 30px 30px;
  padding: 46px 5px 5px 5px;
  width: 92px;
  height: 100%;
  border: 0;
  border-radius: 0px !important;
}

.nav-tabs .nav-item.show .nav-link,
.nav-tabs .nav-link.active {
  background-color: #11293f !important;
}

.shadow.rounded.position-relative {
  height: 100%;
}

.header .headerRight ul {
  display: flex;
  justify-content: center;
  align-items: center;
}

.header .headerRight ul li {
  margin: 0px 5px;
}

.withdraw_btn {
  display: inline-grid;
  grid-gap: 10px 0px;
}

.btn.btn_primary {
  font-size: 11px;
  border-radius: 50px;
  padding: 6px 25px;
  background: #296ca3 !important;
  border-color: #296ca3 !important;
  color: #fff;
}

.rounded.card-holder_qr {
  background: #122b41;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 5px 5px 10px rgba(0, 0, 0, 0.1019607843);
  padding: 20px 0px;
  height: 300px;
}

.rounded.card-holder_qr img {
  width: 200px;
}

.Qr_form {
  padding: 10px 0px !important;
}
.Qr_code {
  width: 50%;
  margin: 10px auto;
}

@media screen and (max-width: 767.98px) {
  .casinoProvidersGames ul li {
    width: calc(50% - 6.66px) !important;
  }
  .HpaySection img,
  .HpaySection canvas {
    width: 260px !important;
    height: 260px !important;
  }
  .HpaySection .HayQR img {
    width: 30px !important;
    height: 30px !important;
    right: 6px !important;
  }
  *:not(:empty) {
    transform-style: inherit !important;
  }
  .WithdrawPageSection .WithDrawSection.tabs-area {
    width: 98% !important;
  }
  .WithdrawGif {
    display: none;
  }
  .WithDrawSection.tabs-area ul li button {
    font-size: 11px !important;
  }
  .WithDrawBankDetails form {
    padding: 30px 0 0 !important;
  }
  .referral_card_main {
    flex-wrap: wrap;
    gap: 6px;
  }
  .referral_card_main .referrals_cards {
    width: 49%;
    flex: inherit;
  }
  .referral_link {
    padding: 6px 10px !important;
  }
  .referral_link h5 {
    width: 20% !important;
    margin-right: 0 !important;
  }
  .betslip-content.inline_betslip .auto-stack ul {
    flex-wrap: wrap;
  }
  .betslip-content.inline_betslip .auto-stack ul li {
    margin: 1% !important;
    width: 23% !important;
  }
  .custom-tabs .tab-pane .game-listing .match-info,
  .sports_page .tab-pane .game-listing .match-info {
    display: block !important;
  }
  .custom-tabs .tab-pane .game-listing .match-info .match-name,
  .sports_page .tab-pane .game-listing .match-info .match-name {
    width: 100% !important;
  }
  .custom-tabs .tab-pane .game-listing .match-info .listing,
  .sports_page .tab-pane .game-listing .match-info .listing {
    padding-bottom: 5px !important;
  }
  .betslip_popup .modal-body .stack-win-value .odds div {
    width: 100% !important;
  }
  .custom-tabs .tab-pane .game-listing .match-info .listing li,
  .sports_page .tab-pane .game-listing .match-info .listing li {
    margin-right: 3px !important;
    width: 100% !important;
  }
  .page-details .game-top-area .date,
  .page-details .game-top-area .range {
    display: none !important;
  }
  .page-details .game-points ul li {
    border-bottom: 1px solid #2f3940 !important;
    display: flex !important;
    padding-left: 0.5rem !important;
  }
  .page-details .game-points ul li .team-name {
    color: #fff !important;
    color: var(--whiteClr) !important;
    flex-direction: revert !important;
    justify-content: space-between !important;
    overflow: hidden !important;
    padding: 0 0.25rem 0 0 !important;
    text-overflow: ellipsis !important;
    white-space: nowrap !important;
    width: calc(100% - 150px) !important;
  }
  .page-details .game-points ul li .status-btns {
    align-items: center !important;
    display: flex !important;
    width: 140px !important;
  }
  .page-details .game-points ul li .status-btns .info-block:first-child {
    display: none !important;
    margin-left: 0 !important;
  }
  .page-details .game-points ul li .status-btns .info-block {
    display: none !important;
    width: inherit !important;
  }
  .page-details .game-points ul li .status-btns .info-block:nth-child(3),
  .page-details .game-points ul li .status-btns .info-block:nth-child(4) {
    display: flex !important;
    width: 50% !important;
  }
  .page-details .game-points ul li .status-btns .info-block.no:nth-child(2) {
    display: flex !important;
    width: 50% !important;
  }
  .banner_sec .banner_imgboxes .img_box {
    flex: 0 0 100% !important;
  }
  .page-details .title span {
    background-color: inherit !important;
  }
}
.Toastify {
  position: relative !important;
  z-index: 9999999999 !important;
}
@media only screen and (max-width: 767px) {
  .gatewaySection .tab-content .tab-pane.active .cards1 {
    width: 48% !important;
  }
  .gatewaySection .tab-content {
    padding: 20px !important;
  }
  .gatewaySection {
    max-width: 340px !important;
  }
}
@media screen and (max-width: 991.98px) {
  .cards {
    width: 49% !important;
  }
  .amount_data {
    flex-wrap: wrap;
    text-align: center;
  }
  .cancel_bonus {
    background: red;
    padding: 4px 2px;
    font-size: 10px;
    display: block;
    cursor: pointer;
    white-space: nowrap;
    border-radius: 5px;
    text-transform: uppercase;
    justify-content: center !important;
    width: 100%;
    text-align: center;
    margin-top: 10px;
  }
}
