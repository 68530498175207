@import "~slick-carousel/slick/slick.css";
@import "~slick-carousel/slick/slick-theme.css";

:root {
  // --primaryClr: #ffbd00;
  --primaryClr: #296ca3;
  --secondaryClr: #132b41;
  --whiteClr: #fff;
  --headClr: #07131c;
  --oddsBack: #72bbef;
  --oddsLay: #f994ba;
}

body {
  background: #0b1e2c;
  color: #fff;
}

.btn-primary,
.btn-primary:hover {
  background: linear-gradient(
    90deg,
    rgba(41, 108, 163, 1) 0%,
    rgba(13, 52, 84, 1) 100%
  ) !important;
  border-color: #296ca3 !important;
}

.button-link {
  font-size: 14px;
  color: #fff;
  text-decoration: none;
  padding: 0;
  margin: 0 5px;
}

.header {
  padding: 15px 0;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 999;
  background: var(--headClr);
  width: 100%;

  .logo {
    max-width: 128px;

    img {
      max-height: 58px;
    }
  }

  .mainNav {
    align-items: center;
    display: none;

    @media screen and (min-width: 768px) {
      display: flex;
    }

    ul {
      li {
        margin-right: 1rem;
        &:last-child {
          margin-right: 0 !important;
        }
        a {
          color: #fff;
          fill: #fff;
          stroke: #fff;
          display: flex;
          align-items: center;
          &.active {
            color: #fbc740;
          }
          svg,
          img {
            max-height: 20px;
            margin-right: 4px;
          }
        }
      }
    }
  }

  .headerRight {
    display: flex;
    align-items: center;
    fill: #ffffff;
    ul {
      li {
        position: relative;
        p {
          position: absolute;
          left: -7px;
          font-size: 12px;
          margin-bottom: 0;
          color: #fff;
        }
      }
    }

    .path-stroke {
      stroke: #ffffff;
    }
    .Deposit_btn {
      button {
        border-radius: 4px;
      }
    }
    .wallet-card {
      border-radius: 4px;
      background: linear-gradient(
        90deg,
        rgb(41, 108, 163) 0%,
        rgb(13, 52, 84) 100%
      ) !important;
      border: 1px solid #296ca3 !important;
    }

    // ul {
    //   display: flex;
    //   align-items: center;
    //   margin-right: 5px;
    //   li {
    //     margin: 0 5px;
    //     position: relative;
    //     .notification-count {
    //       width: 12px;
    //       height: 12px;
    //       background: #ff0000;
    //       color: #fff;
    //       border-radius: 50%;
    //       position: absolute;
    //       right: -2px;
    //       top: -2px;
    //       font-size: 8px;
    //       display: flex;
    //       justify-content: center;
    //       align-items: center;
    //     }
    //   }
    // }

    .menu-btn {
      margin-left: 8px;
      padding: 0;
      background: none;
      border: none;

      &:hover,
      &:focus {
        background: none !important;
        border-color: transparent !important;
      }

      svg {
        width: 25px;
        height: auto;
        fill: #fff;
      }
    }
  }
}

.back-link {
  font-size: 10px;
  color: var(--whiteClr);
  text-transform: uppercase;
  display: flex;
  align-items: center;
  cursor: pointer;
  border: 1px solid;
  padding: 6px;
  border-radius: 5px;
  font-weight: 700;
  &:hover {
    background-color: var(--primaryClr);
  }
  svg {
    margin-left: 5px;
  }
}

.offcanvas {
  border: none !important;
  z-index: 99999999 !important;
}

.offcanvas-header {
  background: #1b1f29;
  color: #fff;

  .profile-img {
    width: 48px;
    height: 48px;
    background: #26324b;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 12px;
    fill: white;
  }

  .user-info {
    color: #fff;
    font-size: 14px;
    font-weight: 300;
    display: flex;
    flex-direction: column;
    justify-content: center;

    span {
      font-size: 16px;
      font-weight: bold;
    }

    p {
      margin: 0;
    }
  }

  .btn-close {
    opacity: 1;
  }
}

.offcanvas-body {
  background: #101520;

  .balance {
    font-size: 16px;
    color: #fff;
    display: flex;
    align-items: center;
    img {
      width: 20px;
      margin-right: 14px;
    }
    svg {
      margin-right: 14px;
      fill: #fff;
      stroke: #fff;
    }
  }

  .balance-amount {
    text-align: right;
    font-weight: bold;
    font-size: 16px;
    color: #fff;
  }

  .btn {
    text-transform: uppercase;
    font-size: 14px;
    font-weight: 500;
    background: linear-gradient(
      90deg,
      rgb(41, 108, 163) 0%,
      rgb(13, 52, 84) 100%
    ) !important;
    color: var(--whiteClr);
    border-color: #296ca3 !important;
  }

  .menu-items {
    border-top: 1px solid #323232;
    margin: 30px -1rem 0 -1rem;

    li {
      border-bottom: 1px solid #323232;

      a {
        display: flex;
        align-items: center;
        padding: 17px 1rem;
        font-size: 16px;
        color: #fff;

        svg {
          margin-right: 15px;
          width: 22px;
          height: auto;
          fill: #fff;
        }
        img {
          width: 22px;
          margin-right: 12px;
        }
      }
    }
  }

  .transfer-form {
    border-top: 1px solid #fff;
    margin: 10px -1rem 0 -1rem;
    padding: 10px;
    overflow: hidden;

    input {
      outline: none;
      padding: 5px 15px;
      font-size: 1rem;
      color: white;
      background: #000;
    }
  }
}

.form-steps {
  text-align: center;
}

.main {
  padding-bottom: 40px;
  padding-top: 110px;
  &.HomePage {
    padding-top: 70px !important;
  }

  .TopCategories {
    margin: -6px 0px 30px 0;

    ul {
      display: flex;
      margin: 0;
      padding: 0;
      background: #215783;
      list-style: none;
      overflow-x: auto;
      border: 0px;
      margin-bottom: 10px;

      li {
        font-size: 11px;
        padding: 0px 0px;
        text-align: center;
        background: #215783;
        min-width: 80px;

        &.active {
          background: #0c1e2c;
        }

        a {
          color: #ffffff;
          text-decoration: none;
          display: flex;
          flex-direction: column;
          align-items: center;

          .IconHolder {
            border-radius: 5px;
            //box-shadow: 0 3px 10px #0f121a;
            padding: 5px 8px 8px 8px;
            display: flex;
            justify-content: center;
            align-items: center;

            img {
              max-height: 25px;
            }
          }

          span {
            margin-top: 4px;
            font-size: 12px;
          }
        }
      }
    }
  }

  .mainBanner {
    margin: 0;

    .slick-prev {
      left: 25px;
      z-index: 2;
    }

    .slick-next {
      right: 25px;
      z-index: 2;
    }

    .slick-slide {
      .content {
        position: absolute;
        width: 100%;
        font-size: 0.85rem;
        z-index: 99;
        bottom: auto;
        left: 0;
        right: auto;
        text-align: left;
        top: 50%;
        -webkit-transform: translateY(-50%);
        transform: translateY(-50%);

        h2 {
          @media screen and (min-width: 768px) {
            font-size: 2rem;
          }

          @media screen and (min-width: 1024px) {
            font-size: 3.5rem;
          }

          span {
            color: #1cc370;
            margin: 0 8px;
          }
        }
      }

      img {
        height: 220px;
        width: 100%;
        object-fit: cover;

        @media screen and (min-width: 768px) {
          height: auto;
          max-height: 530px;
          object-fit: cover;
        }
      }
    }

    .slick-dots {
      bottom: 15px;

      li {
        margin: 0 0.5rem;

        button {
          padding: 0;
          width: 14px;
          height: 14px;

          &::before {
            color: var(--secondaryClr);
            font-size: 20px;
            opacity: 1;
          }
        }

        &.slick-active {
          button {
            &::before {
              color: #fbd35f;
            }
          }
        }
      }
    }
  }

  .sectionTitle {
    font-size: 18px;
    font-weight: normal;
    color: #ffffff;
    position: relative;
    display: inline-block;
    margin-bottom: 20px;
    padding-bottom: 5px;

    &:after {
      content: " ";
      position: absolute;
      left: 0;
      bottom: 0;
      width: 50%;
      height: 1px;
      background: #296ca3;
    }
  }

  .innerBanner {
    margin-bottom: 30px;
    border-radius: 10px;
    overflow: hidden;
    box-shadow: 0 10px 10px #0f121a;

    .link {
      width: 100%;
      background: #296ca3;
      font-size: 11px;
      font-weight: 500;
      display: block;
      text-align: center;
      color: #fff;
      text-transform: uppercase;
      padding: 8px;
    }
  }

  .payment-info {
    color: white;
    @media screen and (min-width: 768px) {
      margin: 11px 0 0 0;
      background-color: var(--headClr);
      padding: 8px;
      padding: 12px;
    }
    ul {
      display: flex;
      align-items: center;
      li {
        width: 4%;
        margin-right: 1%;
      }
    }

    .balance {
      font-size: 16px;
      display: flex;
      align-items: center;
      img {
        width: 28px;
        margin-right: 14px;
      }
      svg {
        margin-right: 14px;
        stroke: #fff;
      }
    }

    .balance-amount {
      text-align: right;
      font-weight: bold;
      font-size: 16px;
    }

    .btn {
      text-transform: uppercase;
      font-size: 14px;
      font-weight: 500;

      &.btn-outline-secondary {
        border-color: #fff;
        color: #fff;
      }
    }
  }

  .account-statement {
    input[type="date"] {
      z-index: 999999 !important;
      padding-right: 7px;
      padding-left: 0.35rem;
      &::-webkit-calendar-picker-indicator {
        filter: invert(1);
      }
    }
    .search-icon {
      button {
        height: calc(100% - 2px) !important;
        top: 1px;
        right: 1px;
        svg {
          display: block;
        }
      }
    }
    button {
      position: absolute;
      right: 0;
      width: 34px;
      display: flex;
      height: 100% !important;
      justify-content: center;
      align-items: center;
      border: 0 !important;
      top: 0;
      padding: 0 !important;
      z-index: 9999 !important;
    }
    @media screen and (min-width: 768px) {
      padding: 0 1rem;
    }
    button.calender-icon {
      svg {
        display: none;
      }
    }

    h3 {
      border-bottom: 1px solid #fff;
      font-size: 16px;
      font-weight: 300;
      margin: 12px -1rem 22px -1rem;
      padding: 0 1rem 12px 1rem;

      @media screen and (min-width: 768px) {
        margin: 12px 0rem 22px 0rem;
        padding-left: 0;
      }
    }

    .btn-primary {
      border-radius: 0;
      padding: 6px 10px;
    }

    ul {
      margin: 0 -1rem;
      border-top: 1px solid #fff;

      &:empty {
        border: none;
      }

      li {
        padding: 22px 1rem;
        border-bottom: 1px solid #fff;

        .h4 {
          font-size: 14px;
          margin-bottom: 0;
        }

        .h5 {
          margin-bottom: 0;
          font-size: 12px;
          letter-spacing: 0.86px;
        }

        .details {
          font-size: 12px;
          font-weight: 300;
        }

        .statue {
          font-size: 10px;
          font-weight: 300;
          letter-spacing: 0.7px;
        }

        small {
          margin-top: 10px;
          font-size: 10px;
          font-weight: 300;
          display: block;
        }
      }
    }
  }

  .profile-img {
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;

    img {
      max-width: 60px;
      width: 60px;
      height: 60px;
      border-radius: 50%;
      border: 2px solid #c9a046;
    }

    span {
      margin-top: 8px;
      font-size: 14px;
      font-weight: 500;
    }
  }

  .edit-btn {
    background: #0b1e2c;
    border: 1px solid #fff;
    position: relative;
    width: 20px;
    height: 20px;
    border-radius: 50%;
    fill: #fff;
    padding: 4px;
    top: 58px;
    right: -25px;
    display: flex;
    justify-content: center;
    align-items: center;
    svg {
      fill: #fff;
    }
  }

  .profile-info {
    th {
      background: #07131c;
      color: var(--whiteClr);
    }

    td {
      background: #22313c;
      color: #fff;
      border-color: #010000;
      button.btn-primary {
        width: 38px;
        height: 38px;
        border-radius: 50%;
        display: flex;
        padding: 5px;
        align-items: center;
        justify-content: center;
        svg {
          font-size: 16px;
        }
      }
    }
    td.reset-pwd {
      display: flex;
      justify-content: flex-end;
      button {
        display: flex;
        color: #000;
        border: 0;
        background: transparent;
        font-size: 13px;
        align-items: center;
      }
    }

    .edit-btn {
      top: 0;
      right: 0;
      margin-left: auto;
    }
  }
}
.modal.reset-modal {
  .modal-content {
    background: rgba(19, 45, 68, 0.874);
    border-radius: 0;
    .modal-header {
      padding: 12px;
      border: 0;
      background: rgba(19, 45, 68, 0.874);
      border-radius: 0;
      button {
        background: transparent url(../public//images/cross-23.svg) 50%/1em auto
          no-repeat;
      }
      .modal-title {
        font-size: 15px;
      }
    }
  }
}

.listings {
  .filter {
    margin-bottom: 20px;
    max-width: 500px;

    .dropdown-toggle {
      background: #c9a145 !important;
      border: none !important;
      border-radius: 0;
      padding: 5px 15px;
    }

    .btn-outline-primary {
      width: 100%;
      padding: 5px;
      background: #fff;
      border-radius: 0 !important;
    }

    .dropdown-menu {
      border-radius: 0;
      border: 1px solid #e8e8e8;
      width: 100% !important;
      white-space: pre-wrap;
      min-width: auto;
      padding: 0 !important;
      margin-top: -3px;

      a {
        border-bottom: 1px solid #e8e8e8;
        color: #26324b;
        font-size: 14px;
        opacity: 0.8;
        padding: 13px 18px;

        &:last-child {
          border: none;
        }
      }
    }
  }

  img {
    border-radius: 7px;
    width: 100%;
    height: 100%;
  }

  .like-btn {
    display: none;
    background: rgba(255, 255, 255, 0.2);
    border: 1px solid rgba(255, 255, 255, 0.4);
    position: absolute;
    bottom: 0;
    right: 0;
    padding: 4px 9px;
    border-radius: 0 0 7px 0;

    svg {
      fill: #fff;
    }
  }
}

.steps-canvas {
  .offcanvas-header {
    background: #1a1e29;

    .offcanvas-title {
      font-size: 20px;
      font-weight: bold;
      color: #fff;
    }
  }

  .form-steps {
    padding: 55px 30px 0;

    .h2 {
      font-size: 28px;
      font-weight: 500;
      color: #fff;
    }

    p {
      font-size: 18px;
      font-weight: 300;
      color: #fff;
    }

    input {
      background: #26324b;
      border-radius: 10px;
      border-color: #26324b;
      padding: 14px 20px;
      color: #fff;
    }

    .icon {
      position: absolute;
      top: 50%;
      right: 15px;
      transform: translate(0, -50%);
      fill: #646464;
      img {
        max-width: 20px;
        opacity: 0.3;
      }
    }

    .btn {
      padding: 9px 30px;
    }

    .form-link {
      font-size: 14px;
      color: var(--whiteClr);
      text-decoration: underline;
    }

    .sign-up {
      font-size: 14px;
      font-weight: 500;

      a {
        font-size: 16px;
        color: #4a6191;
        text-decoration: underline;
      }
    }
  }
}

.tabs-area {
  margin-top: 10px;
  text-align: center;

  ul {
    margin: 0 -1rem;
    flex-wrap: nowrap;

    li {
      width: 100%;

      &:not(:last-child) {
        border-right: 1px solid #666;
      }

      .nav-link {
        width: 100%;
        background: var(--headClr);
        color: rgba(255, 255, 255, 0.6);
        border-radius: 0;
        font-size: 16px;
        font-weight: bold;
        text-transform: uppercase;
        padding: 17px 10px;
        border: none;

        &.active {
          background: #26324b !important;
          color: #f3f3f3;
        }
      }
    }
  }
  #Withdrwa_tab {
    ul {
      li {
        .nav-link.active {
          text-align: left;
        }
      }
    }
  }
  .tab-content {
    .bank-details {
      margin-top: 22px;
      background: #26324b;
      box-shadow: 5px 5px 10px #0000001a;
      border-radius: 10px;
      overflow: hidden;

      .top-area {
        background: #182237;
        width: 100%;
        font-size: 14px;
        padding: 20px;
      }

      .bottom-area {
        padding: 20px;

        table {
          margin-bottom: 0;

          th {
            color: #f3f3f3;
            font-size: 18px;
            font-weight: 500;
            padding: 5px 0;
          }

          td {
            color: #f3f3f3;
            font-size: 14px;
            font-weight: 300;
            padding: 5px 0;
            width: 50%;
            text-align: left;
          }
        }
      }
    }

    .upi-list {
      margin-top: 40px;

      ul {
        display: flex;
        justify-content: center;
        align-items: center;

        li {
          margin: 0 7px;
          width: 32px;
          border: none;
        }
      }
    }

    form {
      padding: 55px 30px 0;
      text-align: center;

      input {
        background: #26324b;
        border-radius: 10px;
        border-color: #26324b;
        padding: 14px 20px;

        &.form-check-input {
          border-radius: 50px;
          width: 24px;
          height: 24px;
          border: 2px solid #296ca3;
          padding: 0;
          background-color: #fff;
          position: relative;

          &:checked:after {
            content: "";
            background: #26324b;
            border-radius: 50px;
            width: 16px;
            height: 16px;
            display: block;
            position: absolute;
            top: 2px;
            left: 2px;
          }
        }
      }

      .btn {
        padding: 9px 30px;
        font-size: 14px;
        text-transform: uppercase;
        min-width: 120px;
      }

      .radio-btns {
        display: flex;

        .form-check {
          display: flex;
          align-items: center;
          font-size: 14px;
          font-weight: 300;

          .form-check-label {
            margin-left: 5px;
            margin-top: 3px;
          }
        }
      }
    }

    .card-holder {
      height: 115px;
      background: #122b41;
      display: flex;
      align-items: center;
      justify-content: center;
      box-shadow: 5px 5px 10px #0000001a;

      img {
        max-height: 40px;
      }
    }
  }

  .h4 {
    font-size: 14px;
    font-weight: 500;
  }

  .sectionTitle {
    &::after {
      background: #26324b;
      width: 100%;
    }
  }
}

.custom-tabs {
  .nav {
    flex-wrap: nowrap;
    margin: 0 -1rem;
    background: #f2f2f2;
    margin-bottom: 20px;
    display: flex;
    justify-content: center;

    li {
      margin: 0 10px;

      .nav-link {
        border-radius: 0;
        font-size: 12px;
        padding: 10px 0;
        color: #26324b;
        font-weight: 500;
        border: none;

        &.active {
          color: #c9a046;
          background: none;
        }
      }
    }
  }

  .tab-pane {
    .title {
      background: #d8d8d8;
      margin: 0 -1rem 10px -1rem;
      padding: 0 1rem;
      display: flex;
      justify-content: space-between;

      span {
        font-size: 14px;
        padding: 6px 1rem;
        display: inline-block;
        background: #c8a044;
        color: #fff;
        margin-left: -1rem;
      }
    }

    .game-listing {
      .breadcrumb {
        font-size: 12px;
        display: flex;
        align-items: center;

        span {
          font-size: 10px;
          color: #838383;
        }
      }

      .status {
        background: #4caf50;
        display: flex;
        padding: 1px 5px;
        font-size: 9px;
        border-radius: 2px;
        align-items: center;
        justify-content: center;
      }

      .listing {
        display: flex;
        flex-wrap: nowrap;

        li {
          margin: 0 2px;
          width: 100%;

          .info-block {
            display: flex;
            flex-direction: column;
            align-items: center;
            background: #899d8a;
            font-size: 10px;
            border-radius: 2px;
            padding: 2px 4px;

            span {
              font-size: 12px;
            }
          }
        }
      }
    }

    .top-row {
      display: flex;
      justify-content: space-between;
      background: #d8d8d8;
      margin: 20px -1rem;
      padding: 0 1rem;

      .bet-btns {
        display: flex;

        li {
          background: #4caf50;
          color: #fff;
          margin: 4px 0px 4px 4px;
          text-transform: uppercase;
          font-size: 11px;
          padding: 4px 8px;
          border-radius: 2px;
        }
      }
    }

    .status-btns {
      display: flex;
      align-items: center;

      .info-block {
        font-size: 10px;
        margin: 4px 0px 4px 4px;
        border-radius: 2px;
        padding: 3px 2px;
        min-width: 36px;
        text-align: center;
        color: #fff;

        &.no {
          background: #796b4a;
        }

        &.yes {
          background: #2b3970;
        }
      }
    }

    .game-points {
      ul {
        margin: 0 -1rem;

        li {
          display: flex;
          border-bottom: 1px solid #eee;
          padding: 3px 1rem;
          align-items: center;
          font-size: 11px;

          .team-name {
            display: flex;
            flex-direction: column;
            line-height: normal;

            .time {
              color: #af2f2f;
            }
          }

          .score {
            margin-left: auto;
          }

          .status-btns {
            margin-left: 5px;
            position: relative;

            .info-block {
              display: flex;
              flex-direction: column;
              align-items: center;
              font-size: 8px;
              line-height: normal;

              span {
                font-size: 12px;
              }
            }

            .points-status {
              position: absolute;
              top: 4px;
              left: 4px;
              width: 95%;
              background: rgba(0, 0, 0, 0.7);
              font-size: 12px;
              color: #fff;
              text-align: center;
              border-radius: 2px;
              height: 80%;
              display: flex;
              justify-content: center;
              align-items: center;
            }
          }
        }
      }
    }

    .game-top-area {
      background: #d8d8d8;
      margin: 0 -1rem;
      padding: 10px 1rem;

      .date {
        text-align: center;
        color: #4caf50;
        font-size: 12px;
        margin-bottom: 5px;
      }

      .title {
        margin: 0;
        padding: 0;
        font-size: 14px;
      }

      .range {
        background: #4caf50;
        font-size: 11px;
        color: #fff;
        padding: 2px 5px;
        line-height: normal;
        border-radius: 2px;
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }
  }
}

.footer {
  padding: 20px 0px 110px 0px;
  background-color: var(--headClr);

  @media screen and (min-width: 768px) {
    padding-bottom: 40px;
  }

  p {
    color: #fff;
    opacity: 0.6;
    font-size: 14px;
    line-height: 24px;
    font-weight: 300;
    text-align: center;
    margin-top: 15px;
    margin-bottom: 0;
  }

  .footer-logo {
    max-width: 130px;
    margin: 0 auto;
  }

  .paymentImages {
    ul {
      display: flex;
      justify-content: center;
      align-items: center;

      li {
        padding: 8px;

        img {
          max-height: 46px;
        }
      }
    }
  }

  .footer-links {
    display: inline;
    margin: 0px auto 10px auto;

    ul {
      text-align: center;
      padding: 0;

      li {
        font-size: 12px;
        font-weight: 300;
        display: inline-block;
        position: relative;
        margin: 5px 15px;

        &:after {
          content: " ";
          position: absolute;
          right: -16px;
          top: 2px;
          width: 1px;
          height: 14px;
          background: #202020;
        }

        &:last-child {
          margin-right: 0;

          &::after {
            display: none;
          }
        }

        a {
          color: var(--whiteClr);
        }
      }
    }
  }

  .social-icons {
    display: none;
    ul {
      display: flex;
      justify-content: center;

      li {
        margin: 10px;

        svg {
          width: 32px;
        }
      }
    }
  }

  .footer-menu {
    position: fixed;
    bottom: 0px;
    background-color: #07111a;
    border-top: 1px solid #0c1e2c;
    z-index: 9999;

    @media screen and (min-width: 768px) {
      display: none;
    }

    ul {
      li {
        text-transform: uppercase;
        font-size: 10px;
        min-width: 54px;

        a {
          padding: 15px 5px 4px 5px;
          color: var(--whiteClr);
          display: flex;
          flex-direction: column;
          align-items: center;
          text-decoration: none;

          svg,
          img {
            fill: currentColor;

            min-height: 25px;
            width: 25px;
            filter: grayscale(100%);
            -webkit-filter: grayscale(100%);
          }

          span {
            margin-top: 5px;
          }

          &.active {
            color: #3aa3f5;

            svg {
              fill: currentColor;
              stroke: currentColor;
            }
          }
        }

        &.home {
          background: #000;
          border-radius: 60px;
          bottom: 21px;
          box-shadow: 0 4px 20px #39a4f4;
          height: 38px;
          min-width: 38px;
          padding: 6px;
          position: relative;
          width: 38px;
          font-size: 0;
          a.active {
            padding: 2px;

            img {
              width: 20px;
              height: 20px;
              min-height: 20px;
            }
          }
          a.aviatorImg {
            padding: 6px 5px 4px 5px !important;
            img {
              min-height: 20px;
              width: 20px;
              min-width: 20px;
            }
            span {
              font-size: 11px;
            }
          }
        }
      }
    }
  }
}

.live_casino_page {
  &.live {
    background: #0c1d2c;
    color: #d8d8d8;
  }

  .header-bg {
    // background-color: #e3e2e2;
    .header {
      margin-bottom: 0;
    }

    .headerRight {
      fill: #296ca3;

      .path-stroke {
        stroke: #296ca3;
      }
    }
  }

  // .exchange_iframe {
  padding-top: 88px;
  height: 100vh;
  // overflow-y: auto;
  // }
  // @media screen and (min-width: 750px) {
  //   padding-top: 60px;
  // }

  // @media screen and (min-width: 1200px) {
  //   padding-top: 70px;
  // }

  scroll-snap-type: mandatory;

  .custom_placeholder_parent {
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    // background: rgba(87, 84, 85, 0.84);

    .custom_placeholder_child {
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 100%;
      max-width: 100px;
    }
  }
}

.wallet-card {
  background: rgba(19, 45, 68, 0.874);
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 5px;
  border-radius: 20px;
  margin-left: 5px;

  @media screen and (max-width: 767.98px) {
  }

  li {
    border-right: 1.5px solid rgba(255, 255, 255, 0.642);
    color: rgba(255, 255, 255, 0.819);
    display: flex;
    justify-content: center;
    flex: 1;
    padding: 0 8px;
    &.bonus_bal {
      border: 0;
    }
  }

  li > span {
    font-weight: bold;
    color: white;
    margin-left: 5px;
  }

  // li:nth-child(1) {
  //   padding-right: 10px;
  // }
  // li:nth-child(2) {
  //   padding: 0 10px;
  // }
  li:nth-child(3) {
    // padding-left: 10px;
    border: none;
  }
}

.exch-funds-form {
  // max-height: 100px;
  > .trigger {
    position: fixed;
    bottom: 100px;
    right: 10px;
    padding: 5px 10px;
    background-color: #296ca3;
    border-radius: 10px;
    // color:

    @media screen and (min-width: 768px) {
      bottom: 40px;
      right: 20px;
    }

    font-size: 16px;
    color: #fff;
    display: flex;
    align-items: center;

    svg {
      margin-right: 14px;
      fill: #fff;
      stroke: #fff;
    }
  }
}

.button-primary.btn.btn-primary {
  padding: 6px 11px !important;
}

@media only screen and (max-width: 575.98px) {
  .main .mainBanner .slick-slide .content h2 {
    font-size: 1.25rem;
  }

  .main .mainBanner .slick-slide .content p {
    font-size: 0.8rem;
  }
  .main .TopCategories ul {
    flex-wrap: nowrap;
    overflow-x: auto;
    border-bottom: 0;
  }
  .main .TopCategories ul li {
    min-width: 92px;
  }
  #mobile_qr img {
    width: 100px;
  }
  #mobile_qr {
    height: 220px;
  }
  .Deposit_btn.ms-2 .btn.btn-primary.w-100 {
    padding: 4px 10px !important;
  }
}
@media only screen and (max-width: 420.98px) {
  .headerRight {
    .after-watsapp-icon {
      margin-top: -10px;
      p {
        font-size: 10px;
      }
    }
    .wallet-card {
      flex-direction: column;
      border-radius: 40px;
      li {
        border-right: 0;
        margin: 0;
        padding: 0;
        font-size: 13px;
      }
    }
    .menu-btn {
      margin-left: 8px !important;
    }
    .Deposit_btn {
      margin-left: 5px !important;
    }
  }
}
.gambling_static_page {
  padding-top: 100px;
  min-height: calc(100vh - 330px);
  .static_page_heading {
    background: #296ca3;
    height: 170px;
    padding-top: 40px;
    @media screen and (max-width: 767.98px) {
      height: 120px;
      padding-top: 25px;
    }
    h1 {
      font-size: 35px;
      font-weight: bold;
      padding-left: 50px;
      @media screen and (max-width: 767.98px) {
        font-size: 22px;
        height: 120px;
        padding-left: 0;
      }
    }
  }
  .static_page_content {
    .content_box {
      background: #0f1319;
      padding: 50px;
      top: -40px;
      position: relative;
      border-radius: 16px;
      @media screen and (max-width: 767.98px) {
        padding: 25px 15px;
      }
      h4 {
        color: #296ca3;
        font-weight: bold;
        margin-bottom: 20px;
      }
      p {
        color: #afb1b7;
        font-size: 16px;
        margin-bottom: 20px;
      }
      ul {
        padding-left: 30px;
        margin-bottom: 20px;
        li {
          color: #afb1b7;
          font-size: 16px;
          list-style-type: disc;
          b {
            color: #296ca3;
          }
        }
      }
      a {
        color: #185180;
        text-decoration: underline;
        transition: all 0.3s ease-in-out;
        &:hover {
          color: #0a58ca;
          text-decoration: none;
        }
      }
    }
  }
}

.form-control {
  color: white !important;
}
.foot-whatsapp {
  width: 32px;
  display: block;
}
.loader-img {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  img {
    width: 300px;
  }
}
.account-statement input[type="date"] {
  z-index: 9;
}
.myprofile {
  .mobile-field {
    background: #eaeaea;
    button {
      @media only screen and (max-width: 767px) {
        position: absolute;
      }
    }
  }
}
@media only screen and (max-width: 767px) {
  .cards {
    width: 100% !important;
  }
  .primary_card .stats-card {
    display: grid !important;
    ul {
      width: 100% !important;
      border-right: 0 !important;
    }
  }
  .Dynamic_deposit_submit {
    display: flex;
    justify-content: center;
    button {
      width: 40% !important;
      font-size: inherit;
    }
  }
  .loader-img_deposit {
    margin: auto;
    width: 20% !important;
    margin-bottom: 15px;
  }
  .test {
    max-height: calc(100vh - 150px) !important;
    height: calc(100vh - 150px) !important;
  }
}
@media only screen and (device-width: 375px) and (device-height: 812px) and (-webkit-device-pixel-ratio: 3) {
  .test {
    max-height: calc(100vh - 65px) !important;
    height: calc(100vh - 65px) !important;
  }
}
@media only screen and (min-device-width: 375px) and (max-device-width: 812px) and (-webkit-min-device-pixel-ratio: 3) {
  .test {
    height: calc(100vh - 170px) !important;
    max-height: calc(100vh - 170px) !important;
  }
}

// sportsbook css
.main {
  &.contact {
    height: 67vh;
  }

  .leftbarSec {
    background: var(--secondaryClr);
    width: 200px;
    height: calc(100vh - 52px);
    overflow-y: auto;
    padding: 10px;
    &::-webkit-scrollbar {
      width: 0;
      height: 0;
    }
    @media only screen and (max-width: 1199.98px) {
      position: fixed;
      transform: translateX(-100%);
      transition: all 0.3s;
      z-index: 999;
    }
    @media only screen and (max-width: 767.98px) {
      width: 70%;
    }
    .games_link {
      h6 {
        color: var(--primaryClr);
        font-size: 13px;
        font-weight: 600;
        text-transform: uppercase;
        padding: 5px 10px;
        border-bottom: 1px solid var(--primaryClr);
      }
      ul {
        padding: 10px 0;
        li {
          cursor: pointer;
          border-bottom: 1px solid #333;
          > div {
            line-height: 35px;
            display: flex;
            align-items: center;
            padding: 5px 10px;
            position: relative;
            &:before {
              position: absolute;
              content: "";
              top: 0;
              left: -10px;
              width: 2px;
              height: 100%;
              background: var(--primaryClr);
              opacity: 0;
              transition: all 0.3s;
            }
            &:hover {
              &:before {
                opacity: 1;
                left: 0;
              }
            }
            img {
              width: 20px;
              height: auto;
            }
            span {
              color: var(--whiteClr);
              font-size: 12px;
              font-weight: 600;
              text-transform: uppercase;
              margin-left: 10px;
              opacity: 0.8;
            }
            .sport-event-count {
              background: var(--primaryClr);
              color: var(--blackClr);
              font-size: 12px;
              margin-left: auto;
              padding: 0.25em 0.4em;
              border-radius: 2px;
            }
          }
        }
      }
    }
  }
  .middleContentSec {
    width: 100%;
    @media only screen and (min-width: 1200px) {
      width: calc(100% - 600px);
      max-height: calc(100vh - 63px);
      overflow-y: auto;
      padding: 0 5px;
      &::-webkit-scrollbar {
        width: 0;
        height: 0;
      }
      @media only screen and (min-width: 1200px) and (max-width: 1299.98px) {
        width: calc(100% - 500px);
      }
    }
  }
  .rightbarSec {
    background: var(--secondaryClr);
    width: 400px;
    height: calc(100vh - 52px);
    overflow-y: auto;
    padding: 10px;
    &::-webkit-scrollbar {
      width: 0;
      height: 0;
    }
    @media only screen and (min-width: 1200px) and (max-width: 1299.98px) {
      width: 300px;
    }
    @media only screen and (max-width: 1199.98px) {
      display: none;
    }
    h6 {
      color: var(--primaryClr);
      font-size: 12px;
      font-weight: 600;
      line-height: 28px;
      padding: 5px 10px;
      border-bottom: 1px solid var(--primaryClr);
    }
    .VSlider {
      background-color: var(--headClr);
      border-radius: 10px;
      padding: 20px 10px;
      margin: 14px 0;
      .slick-slider {
        position: relative;
        .slide_box {
          width: 70%;
          margin: 0 auto;
          position: relative;
          img {
            border-radius: 5px;
          }
          .overlay {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background: rgba(0, 0, 0, 0.45);
            border-radius: 5px;
          }
        }
        .slick-dots {
          top: 40%;
          bottom: auto;
          right: -40px;
          left: auto;
          transform: rotate(90deg);
          width: auto;
          li {
            width: 32px;
            height: 32px;
            transform: rotate(-90deg);
            button {
              border-radius: 50%;
              text-align: center;
              width: 32px;
              height: 32px;
              line-height: 32px;
              padding: 0;
              &::before {
                color: #ccc;
                font-size: 15px;
                font-family: Arial, Helvetica, sans-serif;
                text-shadow: 1px 1px 3px #555;
                width: 32px;
                height: 32px;
                line-height: 30px;
                opacity: 1;
                transition: all 0.4s;
              }
            }
            &:first-child {
              button {
                &::before {
                  content: "01";
                }
              }
            }
            &:nth-child(2) {
              button {
                &::before {
                  content: "02";
                }
              }
            }
            &:nth-child(3) {
              button {
                &::before {
                  content: "03";
                }
              }
            }
            &.slick-active {
              button {
                background: var(--primaryClr);
                &::before {
                  color: var(--whiteClr);
                  font-size: 18px;
                }
              }
            }
          }
        }
      }
    }
    .casino_games {
      display: grid;
      gap: 12px;
      grid-template-columns: repeat(2, 1fr);
      a {
        position: relative;
        img {
          border-radius: 5px;
        }
        .overlay {
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          background: linear-gradient(#3f3f4f 10%, #000 100%);
          color: var(--primaryClr);
          font-weight: 700;
          line-height: 15px;
          display: flex;
          justify-content: center;
          align-items: center;
          text-align: center;
          opacity: 0;
          border-radius: 5px;
          .game_type {
            font-size: 10px;
            text-transform: uppercase;
            max-width: 90px;
            transition: all 0.4s;
          }
        }
        &:hover {
          .overlay {
            opacity: 0.8;
            .game_type {
              transform: scale(1.2);
            }
          }
        }
      }
    }
    &.matched_bets {
      @media only screen and (min-width: 992px) and (max-width: 1199.98px) {
        display: block;
      }
      @media only screen and (max-width: 991.98px) {
        display: none;
      }
      .accordion {
        .accordion-item {
          background-color: #132b41;
          padding-bottom: 5px;
          margin-bottom: 10px;
          border: 0;
          border-radius: 5px;
          box-shadow: 0 0 10px rgb(0 0 0 / 60%);
          .accordion-header {
            .accordion-button {
              background: transparent;
              width: 100%;
              text-align: left;
              padding: 5px 10px;
              border: 0;
              border-bottom: 1px solid var(--primaryClr);
              border-radius: 0;
              box-shadow: none;
              img {
                width: 20px;
                margin-right: 10px;
              }
              span {
                color: var(--primaryClr);
                font-size: 13px;
                font-weight: 600;
                line-height: 28px;
              }
              &:hover,
              &:focus {
                background: transparent !important;
              }
              &:after {
                content: none;
              }
            }
          }
          .accordion-body {
            color: var(--whiteClr);
            padding: 0;
            border: 0;
            .bets_box {
              .bet_info {
                .table {
                  background: var(--headClr);
                  margin-bottom: 0;
                  border-collapse: collapse;
                  overflow-x: auto;
                  border: 1px solid #222;
                  thead {
                    background: #0c1d2c;
                    display: block;
                    tr {
                      th {
                        color: #fff;
                        font-size: 12px;
                        font-weight: 600;
                        overflow: hidden;
                        text-overflow: ellipsis;
                        max-width: 150px;
                        border: 0;
                        &:not(:first-child) {
                          min-width: 60px;
                        }
                        &:first-child {
                          min-width: 35px;
                        }
                        &:last-child {
                          width: 100%;
                        }
                      }
                    }
                  }
                  tbody {
                    display: block;
                    overflow-y: auto;
                    overflow-x: hidden;
                    max-height: 360px;
                    &::-webkit-scrollbar {
                      width: 6px;
                      height: 6px;
                    }
                    &::-webkit-scrollbar-track {
                      background: #222;
                    }
                    &::-webkit-scrollbar-thumb {
                      background: #333;
                      &:hover {
                        background: #444;
                      }
                    }
                    tr {
                      td {
                        color: var(--whiteClr);
                        font-size: 11px;
                        border-color: #333;
                        &:not(:first-child) {
                          min-width: 60px;
                        }
                        &:first-child {
                          min-width: 35px;
                        }
                        &:last-child {
                          width: 100%;
                        }
                        span {
                          color: var(--bodyClr);
                          width: 35px;
                          text-align: center;
                          display: inline-block;
                          border-radius: 4px;
                          &.lay {
                            background: #72bbef;
                          }
                          &.back {
                            background: #faa8ba;
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
            .no_data {
              color: var(--whiteClr);
              font-size: 20px;
              font-weight: 600;
              width: 100%;
              text-align: center;
            }
          }
          &.live_tv {
            .accordion-body {
              background: var(--bodyClr);
              .live_tv_box {
                height: 215px;
              }
            }
          }
          &.bets {
            margin-bottom: 0px;
          }
        }
      }
    }
  }
  .match_slider_sec {
    width: calc(100% - 50px);
    margin-left: 25px;
    .match_info_slider {
      .info_box {
        background: #222430;
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        height: 128px;
        padding: 8px;
        margin: 0 5px;
        .match {
          text-align: center;
          font-size: 10px;
          line-height: 12px;
          .tournament {
            color: #abb0ba;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
          }
          .time {
            color: var(--whiteClr);
            font-weight: 500;
          }
        }
        .participants {
          display: flex;
          position: relative;
          margin: 4px;
          color: var(--whiteClr);
          font-size: 12px;
          font-weight: 500;
          line-height: 14px;
          .item {
            flex: 1;
            overflow: hidden;
            font-size: 10px;
            text-align: center;
            .flag {
              width: 28px;
              height: 28px;
              line-height: 28px;
              margin: 0 auto;
              border-radius: 50%;
            }
            .name {
              line-height: 14px;
              overflow: hidden;
              text-overflow: ellipsis;
              white-space: nowrap;
            }
          }
        }
        .market {
          display: flex;
          gap: 4px;
          .outcome {
            background: #373d57;
            font-size: 14px;
            font-weight: 500;
            line-height: 16px;
            flex: 1;
            display: flex;
            justify-content: space-between;
            padding: 8px;
            border-radius: 4px;
            .name {
              color: #abb0ba;
            }
            .odds {
              color: #e7cf90;
            }
          }
        }
      }
      .slick-arrow {
        background: #222430;
        height: 100%;
        &.slick-prev {
          border-radius: 8px 0 0 8px;
        }
        &.slick-next {
          border-radius: 0 8px 8px 0;
        }
      }
    }
  }
}
.sports_page,
.custom-tabs {
  padding-top: 10px;
  .sports-name-listing {
    ul {
      flex-wrap: nowrap;
      padding: 10px;
      background: var(--secondaryClr);
      margin-bottom: 15px;
      display: flex;
      justify-content: center;
      li {
        margin: 0 5px;
        a,
        div {
          color: #fff;
          padding: 5px;
          display: block;
          cursor: pointer;
          &:hover,
          &.active {
            background: transparent;
            color: var(--primaryClr);
          }
        }
      }
    }
  }
  .nav {
    flex-wrap: nowrap;
    background: var(--headClr);
    // margin-bottom: 15px;
    padding: 10px;
    display: flex;
    justify-content: center;
    .nav-item {
      margin: 0 5px;
      .nav-link {
        color: var(--whiteClr);
        font-size: 12px;
        font-weight: 600;
        text-transform: uppercase;
        padding: 5px;
        border: none;
        border-radius: 0;
        &:hover,
        &.active {
          background: transparent;
          color: var(--primaryClr);
        }
      }
    }
  }
  .tab-pane {
    .title {
      background: var(--headClr);
      padding: 0 1rem;
      display: flex;
      justify-content: space-between;
      display: none;
      @media only screen and (max-width: 767.98px) {
        margin-bottom: 0;
        justify-content: center;
      }
      span {
        font-size: 14px;
        padding: 6px 1rem;
        display: inline-block;
        color: var(--whiteClr);
        margin-left: -1rem;
      }
    }
    .game-listing {
      background: var(--secondaryClr);
      border: 1px solid var(--headClr);
      border-radius: 0 0 0.5rem 0.5rem;
      box-shadow: 0 4px 16px rgba(0, 0, 0, 0.28);

      .match-listing-head {
        display: none;
        ul {
          background-color: #ced5da;
          padding: 0 10px;
          display: flex;
          align-items: center;
          justify-content: space-between;
          li:first-child {
            width: calc(100% - 300px);
            display: block;
          }
          li {
            width: 48px;
            color: #000;
            padding: 5px 3px;
            font-size: 12px;
          }
        }
      }
      .match-info {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 0 6px;
        border-bottom: 1px solid rgba(255, 184, 12, 0.1);
        background: var(--secondaryClr);
        // height: 25px;
        &:last-child {
          border-bottom: 0;
          border-radius: 0 0 5px 5px;
        }
        .live-match {
          height: 8px;
          width: 8px;
          margin-right: 5px;
          background: #c4cfd6;
          border: 1px solid #366601;
          border-radius: 50%;
        }
        .live-match.active {
          background: #6aba12;
          border: 1px solid #366601;
        }
        .match-name {
          display: flex;
          align-items: center;
          justify-content: space-between;
          width: calc(100% - 315px);
          padding: 5px 0;
          .breadcrumb {
            font-size: 12px;
            display: flex;
            align-items: center;
            font-weight: 700;
            color: var(--whiteClr);
            cursor: pointer;
            .date_time_info {
              background: #36404d;
              width: 68px;
              height: 42px;
              overflow: hidden;
              margin-right: 10px;
              padding: 6px 1px;
              border-radius: 5px;
              position: relative;
              .date {
                color: var(--whiteClr);
                font-size: 12px;
                font-weight: 600;
                line-height: 16px;
                text-align: center;
                text-transform: uppercase;
              }
              &.in-play {
                background: var(--headClr);
                .date {
                  color: var(--primaryClr) !important;
                }
                span {
                  position: absolute;
                  background: linear-gradient(
                    to right,
                    #171618,
                    var(--primaryClr)
                  );
                  &:nth-child(1) {
                    top: 0;
                    right: 0;
                    width: 100%;
                    height: 1px;
                    animation: animate1 2s linear infinite;
                    @keyframes animate1 {
                      0% {
                        transform: translateX(-100%);
                      }
                      100% {
                        transform: translateX(100%);
                      }
                    }
                  }
                  &:nth-child(2) {
                    top: 0;
                    right: 0;
                    height: 100%;
                    width: 1px;
                    animation: animate2 2s linear infinite;
                    animation-delay: 1s;
                    @keyframes animate2 {
                      0% {
                        transform: translateY(-100%);
                      }
                      100% {
                        transform: translateY(100%);
                      }
                    }
                  }
                  &:nth-child(3) {
                    bottom: 0;
                    right: 0;
                    width: 100%;
                    height: 1px;
                    animation: animate3 2s linear infinite;
                    @keyframes animate3 {
                      0% {
                        transform: translateX(100%);
                      }
                      100% {
                        transform: translateX(-100%);
                      }
                    }
                  }
                  &:nth-child(4) {
                    top: 0;
                    left: 0;
                    height: 100%;
                    width: 1px;
                    animation: animate4 2s linear infinite;
                    animation-delay: 1s;
                    @keyframes animate4 {
                      0% {
                        transform: translateY(100%);
                      }
                      100% {
                        transform: translateY(-100%);
                      }
                    }
                  }
                }
              }
            }
          }
        }
        .status {
          background: var(--oddsBack);
          color: var(--blackClr);
          display: flex;
          padding: 0 3px;
          font-size: 11px;
          font-style: italic;
          font-weight: 600;
          border-radius: 2px;
          align-items: center;
          justify-content: center;
        }
        .listing {
          display: flex;
          flex-wrap: nowrap;
          li {
            margin-right: 5px;
            width: 43px;
            height: 30px;
            &:nth-child(2),
            &:nth-child(4) {
              margin-right: 10px;
            }
            &:last-child {
              margin-right: 0;
            }
            .info-block {
              display: flex;
              flex-direction: column;
              align-items: center;
              width: 100%;
              padding: 0 2px;
              cursor: pointer;
              height: 30px;
              line-height: 30px;
              border-radius: 5px;
              span {
                color: var(--blackClr);
                font-size: 12px;
                font-weight: 700;
              }
              &.yes {
                background: var(--oddsBack);
              }
              &.no {
                background: var(--oddsLay);
              }
            }
          }
        }
      }
    }
    .top-row {
      display: flex;
      justify-content: space-between;
      background: var(--headClr);
      margin: 20px -1rem;
      padding: 0 1rem;
      .bet-btns {
        display: flex;
        li {
          background: var(--primaryClr);
          color: var(--whiteClr);
          margin: 4px 0px 4px 4px;
          text-transform: uppercase;
          font-size: 11px;
          padding: 4px 8px;
          border-radius: 2px;
        }
      }
    }
    .status-btns {
      display: flex;
      align-items: center;
      .info-block {
        font-size: 10px;
        margin: 4px 0px 4px 4px;
        border-radius: 2px;
        padding: 3px 2px;
        min-width: 36px;
        text-align: center;
        color: var(--whiteClr);
        &.yes {
          background: var(--oddsBack);
        }
        &.no {
          background: var(--oddsLay);
        }
      }
    }
    .game-points {
      ul {
        margin: 0 -1rem;
        li {
          display: flex;
          border-bottom: 1px solid var(--secondaryClr);
          padding: 3px 1rem;
          align-items: center;
          font-size: 11px;
          .team-name {
            display: flex;
            flex-direction: column;
            line-height: normal;
            .time {
              color: var(--siteRedClr);
            }
          }
          .score {
            margin-left: auto;
          }
          .status-btns {
            margin-left: 5px;
            position: relative;
            .info-block {
              display: flex;
              flex-direction: column;
              align-items: center;
              font-size: 8px;
              line-height: normal;
              span {
                font-size: 12px;
              }
            }
            .points-status {
              position: absolute;
              top: 4px;
              left: 4px;
              width: 95%;
              background: rgba(0, 0, 0, 0.7);
              font-size: 12px;
              color: var(--whiteClr);
              text-align: center;
              border-radius: 2px;
              height: 80%;
              display: flex;
              justify-content: center;
              align-items: center;
            }
          }
        }
      }
    }
    .game-top-area {
      background: var(--headClr);
      margin: 0 -1rem;
      padding: 10px 1rem;
      .date {
        text-align: center;
        color: var(--primaryClr);
        font-size: 12px;
        margin-bottom: 5px;
      }
      .title {
        margin: 0;
        padding: 0;
        font-size: 14px;
      }
      .range {
        background: var(--primaryClr);
        font-size: 11px;
        color: var(--blackClr);
        padding: 2px 5px;
        line-height: normal;
        border-radius: 2px;
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }
  }
}

.sports_page {
  .tab-content {
    .tab-pane {
      max-height: calc(100vh - 120px);
      padding-bottom: 10px;
      overflow-y: auto;
    }
  }
}
.match_details_container {
  background: #101010;
  padding-top: 15px;
  .math_info {
    text-align: center;
    .match_type {
      background: var(--primaryClr);
      color: #fff;
      font-size: 13px;
      font-weight: 600;
      text-transform: uppercase;
      text-shadow: 1px 1px 3px rgb(0 0 0 / 50%);
      word-spacing: 3px;
      width: fit-content;
      height: 34px;
      line-height: 34px;
      padding: 0 15px;
      margin: 0 auto;
      border-radius: 25px;
      position: relative;
      z-index: 1;
      animation: shine 3s linear infinite;
      @keyframes shine {
        0% {
          -webkit-box-shadow: 0 0 0 0 var(--primaryClr);
          box-shadow: 0 0 0 0 var(--primaryClr);
        }
        40% {
          -webkit-box-shadow: 0 0 0 20px rgba(0, 123, 255, 0);
          box-shadow: 0 0 0 20px rgba(0, 123, 255, 0);
        }
        80% {
          -webkit-box-shadow: 0 0 0 20px rgba(0, 123, 255, 0);
          box-shadow: 0 0 0 20px rgba(0, 123, 255, 0);
        }
        100% {
          -webkit-box-shadow: 0 0 0 0 rgba(0, 123, 255, 0);
          box-shadow: 0 0 0 0 rgba(0, 123, 255, 0);
        }
      }
      &:after {
        position: absolute;
        content: "";
        top: 16px;
        left: -40%;
        background: var(--primaryClr);
        width: 180%;
        height: 2px;
        z-index: -1;
        @media screen and (max-width: 767px) {
          width: 140%;
          left: -20%;
        }
      }
    }
    .team_names {
      margin: 15px 0 10px;
      span {
        color: var(--primaryClr);
        font-size: 16px;
        font-weight: 600;
        text-transform: uppercase;
        @media screen and (max-height: 575.98px) {
          font-size: 13px;
        }
        span {
          color: rgb(237, 33, 58);
          font-size: 12px;
          @media screen and (max-height: 575.98px) {
            font-size: 10px;
          }
        }
        &:last-child {
          span {
            display: none;
          }
        }
      }
    }
    .collapse_container {
      display: flex;
      align-items: center;
      justify-content: center;
      padding-bottom: 10px;
      .btn {
        background: linear-gradient(
          to right,
          #1186d3 0,
          #41aaf0 15%,
          #41aaf0 30%,
          #1186d3 55%,
          #1186d3 100%
        ) !important;
        border: 0;
        border-radius: 5px;
        padding: 4px 6px;
        &:hover,
        &:focus {
          background: linear-gradient(
            to right,
            #1186d3 0,
            #41aaf0 15%,
            #41aaf0 30%,
            #1186d3 55%,
            #1186d3 100%
          ) !important;
        }
        img {
          width: 14px;
        }
      }
      .in_play {
        background: #111;
        color: #41aaf0;
        text-transform: uppercase;
        letter-spacing: 2px;
        overflow: hidden;
        font-weight: 700;
        margin: 0 15px;
        padding: 2px 6px;
        position: relative;
        .anim_line1 {
          position: absolute;
          top: 0;
          right: 0;
          width: 100%;
          height: 1px;
          background: linear-gradient(to right, #171618, #1186d3);
          animation: animate1 2s linear infinite;
        }
        .anim_line2 {
          position: absolute;
          top: 0;
          right: 0;
          height: 100%;
          width: 1px;
          background: linear-gradient(to bottom, #171618, #1186d3);
          animation: animate2 2s linear infinite;
          animation-delay: 1s;
        }
        .anim_line3 {
          position: absolute;
          bottom: 0;
          right: 0;
          width: 100%;
          height: 1px;
          background: linear-gradient(to left, #171618, #1186d3);
          animation: animate3 2s linear infinite;
        }
        .anim_line4 {
          position: absolute;
          top: 0;
          left: 0;
          height: 100%;
          width: 1px;
          background: linear-gradient(to top, #171618, #1186d3);
          animation: animate4 2s linear infinite;
          animation-delay: 1s;
        }
      }
    }
  }
  .sports-match-iframe {
    height: 100%;
    @media screen and (min-width: 1400px) {
      height: 390px;
    }
    @media screen and (min-width: 1200px) and (max-width: 1399.98px) {
      height: 330px;
    }
    @media screen and (min-width: 1024px) and (max-width: 1199.98px) {
      height: 361px;
    }
    @media screen and (min-width: 992px) and (max-width: 1023.98px) {
      height: 311px;
    }
    @media screen and (min-width: 880px) and (max-width: 991.98px) {
      height: 391px;
    }
    @media screen and (min-width: 768px) and (max-width: 879.98px) {
      height: 361px;
    }
    @media screen and (min-width: 650px) and (max-width: 767.98px) {
      height: 321px;
    }
    @media screen and (min-width: 576px) and (max-width: 649.98px) {
      height: 291px;
    }
    @media screen and (min-width: 480px) and (max-width: 575.98px) {
      height: 381px;
    }
    @media screen and (min-width: 420px) and (max-width: 479.98px) {
      height: 350px;
    }
    @media screen and (min-width: 380px) and (max-width: 419.98px) {
      height: 330px;
    }
    @media screen and (min-width: 360px) and (max-width: 379.98px) {
      height: 310px;
    }
    @media screen and (min-width: 320px) and (max-width: 359.98px) {
      height: 290px;
    }
    @media screen and (max-width: 319.98px) {
      height: 280px;
    }
  }
}
.page-details {
  .game-points {
    padding-bottom: 0;
  }
  .top-row {
    display: flex;
    justify-content: space-between;
    background: var(--headClr);
    margin-bottom: 10px;
    padding: 0.5rem 1rem;
    ul.bet-btns {
      display: flex;
      li {
        background: var(--primaryClr);
        color: var(--blackClr);
        margin: 4px 0px 4px 4px;
        text-transform: uppercase;
        font-size: 11px;
        padding: 4px 8px;
        border-radius: 2px;
      }
    }
  }
  .game-top-area {
    background: #132b41;
    margin-bottom: 0px;
    padding: 5px 1rem 5px 1rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: relative;
    .date {
      text-align: center;
      color: var(--whiteClr);
      font-size: 12px;
      margin-bottom: 5px;
      order: 2;
      margin-bottom: 0;
    }
    .title {
      background: transparent;
      margin: 0;
      padding: 0;
      font-size: 14px;
      line-height: 30px;
      color: #ffffff;
      font-weight: bold;
    }
    .range {
      position: absolute;
      left: 50%;
      transform: translate(-50%);
      float: none;
      padding: 0 6px;
      margin: 7px 0;
      background: var(--primaryClr);
      font-size: 12px;
      line-height: 16px;
      border-radius: 3px;
      color: #000;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
  .title {
    background: #132b41;
    padding: 0 1px 0 1rem;
    display: flex;
    justify-content: space-between;
    @media only screen and (max-width: 767.98px) {
      background-color: #3b5160;
      margin-bottom: 0;
      margin-top: 0 !important;
      padding: 0 0.25rem 0 0.5rem;
    }
    span {
      font-size: 14px;
      padding: 6px 0;
      display: inline-block;
      color: var(--whiteClr);
    }
    .status-btns {
      display: flex;
      align-items: center;
      @media screen and (max-width: 767.98px) {
        width: 136px;
      }
      .info-block {
        font-size: 13px;
        font-weight: 600;
        color: var(--blackClr);
        text-align: center;
        width: 68px;
        height: 26px;
        line-height: 22px;
        display: flex;
        flex-direction: column;
        align-items: center;
        margin: 2px 1px 1px;
        padding: 3px 2px;
        border-radius: 2px;
        &.yes {
          background: var(--oddsBack);
        }
        &.no {
          background: var(--oddsLay);
        }
        @media screen and (max-width: 767.98px) {
          width: 50%;
        }
      }
    }
  }
  .game-points {
    border: 1px solid #132b41;
    box-shadow: 0 4px 16px rgba(0, 0, 0, 0.28);
    padding-bottom: 1rem;
    border-radius: 0 0 0.5rem 0.5rem;
    ul {
      li {
        display: flex;
        border-bottom: 1px solid var(--secondaryClr);
        padding: 1px 0 1px 0.75rem;
        align-items: center;
        .score {
          margin-left: auto;
          margin-right: 5px;
        }
        .team-name {
          font-size: 12px;
          font-weight: 700;
          line-height: normal;
          display: flex;
          flex-direction: column;
          .time {
            color: var(--siteRedClr);
          }
        }
        .status-btns {
          display: flex;
          align-items: center;
          position: relative;
          .points-status {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            background-color: rgba(0, 0, 0, 0.6);
            text-shadow: 0 0.26667vw 1.06667vw rgb(0 0 0 / 50%);
            font-size: 12px;
            color: var(--primaryClr);
            text-align: center;
            border-radius: 5px;
            height: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
            &::after {
              position: absolute;
              top: 7px;
              left: 5px;
              border: 1px solid var(--primaryClr);
              content: "";
              padding: 10px;
              width: calc(100% - 10px);
              height: 24px;
              border-radius: 5px;
            }
          }
          &.match_odds {
            .info-block {
              &:nth-child(1),
              &:nth-child(2),
              &:nth-child(5),
              &:nth-child(6) {
                background: #e9e9e9;
              }
            }
          }
        }
        .info-block {
          display: flex;
          flex-direction: column;
          align-items: center;
          font-size: 8px;
          line-height: normal;
          font-size: 10px;
          margin: 2px 1px 1px;
          padding: 5px 2px;
          width: 68px;
          text-align: center;
          color: var(--blackClr);
          cursor: pointer;
          border-radius: 4px;
          &.yes {
            background: var(--oddsBack);
          }
          &.no {
            background: var(--oddsLay);
          }
          span {
            font-size: 13px;
            font-weight: 500;
          }
        }
      }
    }
  }
}
.sports_page_banner .slick-slider .slick-dots {
  bottom: 8px;
}
.sports_page_banner .slick-slider .slick-dots li {
  height: 3px;
}
.sports_page_banner .slick-slider .slick-dots li button {
  height: 3px;
  padding: 0;
}
.sports_page_banner .slick-slider .slick-dots li button:before {
  background: hsla(0, 0%, 100%, 0.7);
  color: transparent;
  height: 3px;
  opacity: 1;
  width: 25px;
}
.sports_page_banner .slick-slider .slick-dots li.slick-active button:before {
  background-color: #296ca3;
}
.betslip_popup {
  .modal-body {
    background: #1a212a;
    input {
      background-color: #101924 !important;
      border-color: #67717e !important;
    }
    .stack-win-value {
      flex-wrap: wrap;
      div {
        width: 46%;
      }
    }
    .auto-stake {
      ul {
        li {
          .stack-btn {
            background: var(--secondaryClr);
            color: var(--whiteClr);
          }
        }
      }
    }
  }
}
.betslip-content.inline_betslip {
  padding: 0.5rem;
  border: 1px solid var(--secondaryClr);
  box-shadow: 0 2px 4px rgba(255, 189, 0, 0.12);
  .stack-win-value {
    display: flex;
    margin-bottom: 5px;
    > div {
      width: calc(33.33% - 5px);
      margin-right: 10px;
      &:last-child {
        margin-right: 0;
      }
    }

    .odds,
    .stack_field_value {
      .form-control {
        border-color: var(--secondaryClr);
      }
    }
  }
  .winning p {
    line-height: 1.3;
    margin-bottom: 0;
  }
  .auto-stack {
    margin-bottom: 10px;
    ul {
      display: flex;
      align-items: center;
      li {
        width: 20%;
        margin-right: 0.5rem;
        &:last-child {
          margin-right: 0;
        }
        .stack-btn {
          background: var(--secondaryClr);
          color: var(--whiteClr);
          width: 100%;
        }
      }
    }
  }
  .btn_group {
    display: flex;
    > div {
      width: calc(50% - 5px);
      margin-right: 10px;
      &:last-child {
        margin-right: 0;
      }
    }
    .bet-now {
      .place-btn {
        background: #0cd664;
        color: #000;
        font-size: 1.15rem;
        font-weight: 600;
        width: 100%;
      }
    }
    .cancel-btn {
      background: #af2f2f;
      color: #000;
      font-size: 1.15rem;
      font-weight: 600;
      width: 100%;
    }
  }
}

.cashier {
  .payment-info {
    background-color: var(--headClr);
    padding: 25px;
    margin-bottom: 15px;
  }
}

.gatewaySection {
  max-width: 800px;
  margin: 0px auto 15px;
  ul.nav-tabs {
    border: 0;
    margin-bottom: 0 !important;
    li {
      width: 50%;
      button {
        background: linear-gradient(0deg, #031c2a 0%, #213040 100%);
        border-radius: 15px 15px 0px 0px;
        border: 0;
        width: 100%;
        color: #fff;
        &.active {
          background: linear-gradient(180deg, #fac640 32.81%, #fac640);
          border-radius: 15px 15px 0 0;
          color: #fff;
        }
      }
    }
  }
  .tab-content {
    background: var(--headClr);
    padding: 20px 50px;
    .tab-pane.active {
      display: flex;
      flex-wrap: wrap;
      .cards1 {
        cursor: pointer;
        background: linear-gradient(0deg, #031c2a 0%, #213040 100%);
        border: 0.5px solid #414141;
        box-sizing: border-box;
        border-radius: 10px;
        width: 31.3333%;
        float: left;
        margin: 1% 1%;
        .card-icon {
          padding: 12px 5px 4px 5px;
          font-size: 12px;
          border-bottom: 0.5px solid #414141;
          text-align: center;
          img {
            width: 64px;
          }
        }
        p {
          padding: 11px 4px;
          font-size: 14px;
          font-size: 12px;
          margin: 0;
          text-align: center;
        }
      }
    }
  }
}

.valuesDeposit {
  display: flex;
  justify-content: center;
  align-items: center;
  background: var(--headClr);
  padding: 8px;
  border-radius: 5px;
  margin-top: 12px;
  h3 {
    margin-bottom: 0;
    margin-right: 12px;
  }
  .copyValue {
    justify-content: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    background: #0b1e2c;
    padding: 5px;
    border-radius: 5px;
    font-size: 12px;
  }
}
.payment-info.social {
  background-color: transparent !important;
  position: fixed;
  right: 0;
  top: 30%;
  width: 70px;
  margin: -30px -1rem;
  z-index: 99;

  ul {
    flex-direction: column;
    position: relative;
    li {
      margin-bottom: 10px;
      a {
        align-items: center;
        background-color: rgb(0 0 0 / 56%);
        border: 2px solid #4a4a4a;
        border-radius: 5px;
        display: flex;
        height: 60px;
        position: relative;
        right: 45px;
        transition: all 0.3s;
        width: 70px;
        cursor: pointer;
        &:hover {
          right: 250px;
          width: 300px;
        }
        img {
          height: auto;
          margin: 0 15px;
          width: 35px;
        }
        p {
          color: #fff;
          display: flex;
          flex-direction: column;
          font-size: 16px;
          font-weight: 500;
          line-height: 20px;
          margin: 0;
        }
      }
    }
  }
}
.loader-img_deposit {
  margin: auto;
  width: 10%;
  margin-bottom: 15px;
}
.Dynamic_deposit_submit {
  display: flex;
  justify-content: center;
  button {
    width: 20%;
    font-size: inherit;
  }
}

.heading1 {
  display: flex;
  justify-content: space-between;
  background: #296ca3;
  padding: 10px;
  align-items: center;
  h4 {
    margin: 0;
  }
}
.new_table {
  thead {
    th {
      padding: 5px;
      color: #fff;
      white-space: nowrap;
    }
    background: var(--secondaryClr2);
  }
  tbody {
    background: #11293f;
    color: #fff;
    tr td {
      border: 0;
    }
  }
  background: var(--headClr);
  padding: 6px;
}
.primary_card {
  border-radius: 10px;
  border: 1px solid #d58b2f;
  .stats-card {
    display: flex;
    ul {
      border-right: 1px solid #353c4b;
      width: 50%;
      &:last-child {
        border: 0;
      }
      li {
        // height: 50px;
        display: flex;
        justify-content: space-between;
        padding: 10px;
        border-bottom: 1px solid #353c4b;
        font-weight: 700;
        span {
          width: 50%;
          // font-size: 13px;
          display: block;
          font-weight: normal;
        }
      }
    }
  }
}
.main_card {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  .progress {
    background: #393939;
  }
}
.cards {
  span {
    color: orange;
    margin-left: 4px;
  }
  border: 1px solid var(--primaryClr);
  padding: 15px;
  border-radius: 10px;

  width: 32.33%;
  margin: 0.5%;
}
.amount_data {
  justify-content: space-between;
}

.cancel_bonus {
  background: red;
  padding: 1px 2px;
  font-size: smaller;
  cursor: pointer;
  border-radius: 5px;
  text-transform: uppercase;
}

.amount_data {
  .status {
    color: green;
  }
  .status_claimed {
    color: #f1c232;
  }
  .staus_pending {
    color: #f1c232;
  }
  .staus_expired {
    color: #cc0000;
  }
  .staus_canceled {
    color: #cc0000;
  }
}
.referral_card_main {
  display: flex;
  justify-content: normal !important;
  margin-top: 10px !important;
  gap: 15px;
  .referrals_cards {
    margin-top: 10px;
    width: 100%;
    background: var(--headClr);
    border-radius: 5px;
    flex: 1 1;
    h5 {
      font-size: 1rem;
    }
    .referr_user {
      display: flex;
      gap: 10px;
      svg {
        fill: var(--primaryClr);
      }
      p {
        font-size: 15px;
        font-weight: 500;
        margin-bottom: 0;
      }
    }
  }
}
.referral_link {
  background-color: var(--headClr);
  border-radius: 5px;
  display: flex;
  align-items: center;
  gap: 10px;
  padding: 6px 20px;
  button {
    width: 50px;
    height: 50px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0;
    svg {
      font-size: 18px;
    }
  }
  h5 {
    font-size: 15px;
    font-weight: 400;
    margin-bottom: 0;
    margin-right: 20px;
  }
  div {
    background: var(--secondaryClr);
    border-radius: 5px;
    color: #e7e4e4;
    flex: 1 1;
    padding: 8px 15px;
  }
}
.referr_tabs ul {
  border-bottom: 0;
  gap: 20px;
  button {
    background: #333334 !important;
    border: 0 !important;
    color: #fff !important;
    padding: 8px 35px;
  }
}
.referr_table {
  color: #fff;
  thead {
    background: #333334;
    border: 0;
    th {
      border: 0;
    }
  }
  tbody {
    background-color: #000;
    td {
      border-color: #333;
    }
  }
}
.cancel_bonus_modal {
  .modal-dialog {
    .modal-content {
      background: #fff;
      color: #000;
    }
  }
}

.WithdrawPageSection {
  .WithDrawSection.tabs-area {
    width: 760px;
    ul {
      border: 0;
      background: #2c2f3d;
      li {
        button {
          color: var(--whiteClr) !important;
          border-radius: 0 !important;
          text-align: center;
          font-weight: 400;
          border: 1px solid #444;
          background: rgb(44, 47, 61);
          background: linear-gradient(
            100deg,
            rgba(44, 47, 61, 1) 0%,
            rgba(57, 67, 91, 1) 100%
          );
          padding: 10px !important;
          &.active {
            background: rgb(6, 99, 177);
            background: linear-gradient(
              100deg,
              rgb(43 133 243) 0%,
              rgb(131 175 228) 100%
            ) !important;
          }
        }
      }
    }
    .tab-content {
      padding-bottom: 0 !important;

      .WithDrawBankDetails {
        background-color: #021622;
        background-color: #021622;
        background: url("./assets/images/banner/withdrawbackground.jpg") #021622;
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center;
        padding: 10px;
        .heading {
          display: none;
        }
        .playerBankDetails {
          input,
          select {
            height: 45px;
            border-radius: 3px !important;
          }
          input {
            background: transparent !important;
            color: var(--whiteClr) !important;
            border: 1px solid var(--whiteClr) !important;
            &::placeholder {
              color: var(--whiteClr);
            }
            &:-webkit-autofill,
            &:-webkit-autofill:hover,
            &:-webkit-autofill:focus,
            &:-webkit-autofill:active {
              box-shadow: 0 0 0 30px var(--whiteClr) inset !important;
              -webkit-text-fill-color: var(--blackClr) !important;
            }
          }
          button.btn-primary {
            background: rgb(6, 99, 177);
            background: linear-gradient(
              100deg,
              rgba(6, 99, 177, 1) 0%,
              rgba(40, 131, 239, 1) 100%
            );
            border-radius: 5px !important;
            border: 0;
            font-weight: 400;
            height: 45px;
          }
        }
        h4 {
          font-size: 18px;
          margin-bottom: 15px;
        }
        .WithdrawTable {
          thead {
            border: 1px solid #7f898f;
            border-radius: 3px !important;
            th {
              white-space: nowrap;
            }
          }
          tbody {
            tr {
              td {
                color: var(--whiteClr) !important;
                padding: 12px 0 !important;
                line-height: 30px;
                input[type="checkbox"] {
                  display: none;
                }
                label {
                  display: block;
                  margin: 0.2em;
                  cursor: pointer;
                  padding: 0.2em;
                }
                button {
                  margin-right: 8px;
                }
              }
              th,
              td {
                color: var(--whiteClr) !important;
                padding: 12px !important;
                line-height: 30px;
                background: transparent !important;
                white-space: nowrap;
              }
            }
          }
        }
      }

      .WithdrawRequestSection {
        ul.nav-tabs {
          margin-top: 10px;
          background-color: var(--whiteClr) !important;
          button {
            background: var(--whiteClr) !important;
            color: #000 !important;
            text-align: center !important;
            &.active {
              background: #169f00 !important;
              color: var(--whiteClr) !important;
            }
          }
        }
        .tab-content {
          background: url("./assets/images/banner/withdrawbackground.jpg")
            #021622;
          background-repeat: no-repeat;
          background-size: cover;
          background-position: center;
          padding: 10px;
          input {
            background: transparent !important;
            color: var(--whiteClr) !important;
            border: 1px solid var(--whiteClr) !important;
            &::placeholder {
              color: var(--whiteClr);
            }
            &:-webkit-autofill,
            &:-webkit-autofill:hover,
            &:-webkit-autofill:focus,
            &:-webkit-autofill:active {
              box-shadow: 0 0 0 30px var(--whiteClr) inset !important;
              -webkit-text-fill-color: var(--blackClr) !important;
            }
          }
        }

        .WithdrawRequestBankSection {
          background-color: #021622;
          padding: 10px;
          .WithdrawTable {
            border: 0;
            thead {
              background-color: #2c2f3d;
              border: 0;
              border-radius: 3px !important;
              th {
                border: 0;
                color: var(--whiteClr);
                white-space: nowrap;
                background: transparent;
              }
            }
            tbody {
              tr {
                background: transparent;
                th {
                  background: transparent;
                  line-height: 40px;
                  color: var(--whiteClr);
                }
                td {
                  color: var(--whiteClr) !important;
                  padding: 12px 0.5rem !important;
                  line-height: 30px;
                  background: transparent;
                  button {
                    margin-right: 8px !important;
                  }
                }
              }
            }
          }
        }
        .sectionTitle {
          color: var(--whiteClr) !important;
          margin: 18px 0 25px !important;
          position: relative;
          text-align: center !important;
          &::after {
            position: absolute;
            content: "";
            border: 1px solid var(--whiteClr);
            left: 50%;
            transform: translate(-50%, -50%);
            width: 120px;
            bottom: 0;
          }
        }
        button.btn-primary {
          background: rgb(6, 99, 177);
          background: linear-gradient(
            100deg,
            rgba(6, 99, 177, 1) 0%,
            rgba(40, 131, 239, 1) 100%
          );
          border-radius: 5px !important;
          margin-bottom: 10px;
          margin-right: 5px;
          border: 0;
          font-weight: 400;
          height: 45px;
        }
      }
    }
  }
}

.updateBankDeatils {
  z-index: 999999;
  .modal-header {
    background-color: var(--headClr);
    color: var(--whiteClr);
    border: 0;
    button {
      filter: invert(1);
    }
  }
  .modal-body {
    background: #000;
    input {
      background: transparent !important;
      color: var(--whiteClr) !important;
      border: 1px solid var(--whiteClr) !important;
      &::placeholder {
        color: var(--whiteClr);
      }
      &:-webkit-autofill,
      &:-webkit-autofill:hover,
      &:-webkit-autofill:focus,
      &:-webkit-autofill:active {
        box-shadow: 0 0 0 30px var(--whiteClr) inset !important;
        -webkit-text-fill-color: var(--blackClr) !important;
      }
    }
    button.btn-primary {
      background: rgb(6, 99, 177);
      background: linear-gradient(
        100deg,
        rgba(6, 99, 177, 1) 0%,
        rgba(40, 131, 239, 1) 100%
      );
      border-radius: 5px !important;
      border: 0;
      font-weight: 400;
      height: 35px;
      margin: 0 auto;
      width: 85px;
      text-align: center;
      font-size: 15px;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
}

.WithdrawGif {
  img {
    animation: moving 3s infinite linear;
    margin-left: 20px;
    width: 365px;
    @keyframes moving {
      0% {
        left: 0;
      }
      50% {
        left: 0;
        -webkit-transform: translateY(15px);
        transform: translateY(15px);
      }
      100% {
        left: 0;
      }
    }
  }
}

.WithdrawTable tbody tr td input[type="checkbox"] + label {
  display: block;
  margin-bottom: 0;
  cursor: pointer;
  padding: 0.2em;
}
.WithdrawTable tbody tr td input[type="checkbox"] {
  display: none;
}
.WithdrawTable tbody tr td input[type="checkbox"] + label:before {
  content: "\2714";
  border: 0.1em solid #fff;
  border-radius: 0.2em;
  display: inline-block;
  width: 2em;
  height: 2em;
  padding-left: 0.5em;
  padding-bottom: 0.3em;
  vertical-align: bottom;
  color: transparent;
  transition: 0.2s;
}
.WithdrawTable tbody tr td input[type="checkbox"]:checked + label:before {
  background-color: MediumSeaGreen;
  border-color: MediumSeaGreen;
  color: #fff;
}

.WithdrawPageSection
  .WithDrawSection.tabs-area
  .tab-content
  .WithdrawRequestSection
  .tab-content
  input:disabled {
  background: #415059 !important;
  border: 0 !important;
  cursor: no-drop !important;
}

.PagesHeading {
  background-color: var(--secondaryClr);
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px;
  height: 50px;
  margin-bottom: 20px;
  h2 {
    font-size: 18px;
    margin: 0;
    color: var(--whiteClr);
  }
}

.privay-content,
.responsible-game {
  h3,
  h2 {
    color: #e9c149;
    font-size: 22px;
  }
}

.react-tel-input .country-list .country.highlight {
  color: var(--headClr);
}
.reset-modal {
  .icon {
    position: absolute;
    top: 75%;
    right: 15px;
    transform: translate(0, -50%);
    fill: var(--whiteClr);
    img {
      width: 22px;
      margin: 0 !important;
    }
  }
}

.LoginBtn {
  flex-wrap: wrap;
  justify-content: center;
  gap: 8px;
}
.slots-section {
  .slots-tabs {
    display: flex;
    gap: 10px;
    margin-bottom: 10px;
    li {
      width: calc(50% - 5px);
      a {
        background: var(--secondaryClr);
        color: var(--whiteClr);
        text-align: center;
        display: block;
        width: 100%;
        padding: 7px 25px;
        border-radius: 4px;
        &.active {
          background: linear-gradient(
            90deg,
            rgb(13, 52, 84) 0%,
            rgb(41, 108, 163) 100%
          );
          box-shadow: 0 2px 12px rgba(0, 0, 0, 0.45);
        }
      }
    }
  }
  .games-list {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
    li {
      width: calc(20% - 8px);
      @media (max-width: 991.98px) and (min-width: 768px) {
        width: calc(25% - 7.5px);
      }
      @media (max-width: 767.98px) and (min-width: 576px) {
        width: calc(33.33% - 6.66px);
      }
      @media (max-width: 575.98px) {
        width: calc(50% - 5px);
      }
      a {
        background: var(--secondaryClr);
        display: block;
        overflow: hidden;
        border-radius: 5px;
        box-shadow: 0 2px 12px rgba(0, 0, 0, 0.45);
        img {
          border-radius: 5px 5px 0 0;
          transition: all 0.5s;
        }
        span {
          color: var(--whiteClr);
          font-size: 13px;
          display: block;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          padding: 3px 5px;
          opacity: 0.7;
        }
        &:hover {
          img {
            transform: scale(1.05);
          }
        }
      }
    }
  }
}

.qrSize {
  width: 250px;
}

.HpaySection {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  max-width: 350px;
  background: var(--blackClr);
  position: relative;
  margin: 30px auto;
  .HayQR {
    cursor: pointer;
    img {
      width: 30px !important;
      height: 30px !important;
      position: absolute;
      right: -14px;
      top: -16px;
      margin-top: 0 !important;
    }
  }
  img,
  canvas {
    width: 300px !important;
    height: 300px !important;
    margin-top: 10px !important;
  }
}

.marqu {
  height: 25px;
  background: #201e1e;
  display: flex;
  align-items: center;
  position: relative;
  z-index: 9999;
  img {
    width: 35px;
  }
  marquee {
    width: 100%;
  }
  span {
    position: absolute;
    width: 35px;
    height: 25px;
    top: 0;
    right: 0;
    background: #201e1e;
    display: flex;

    color: #fff;
    justify-content: center;
    align-items: center;
    font-size: 15px;
  }
}

.MaintainSection {
  display: flex;
  align-items: center;
  height: 100vh !important;
  padding: 0 40px;
  .MaintainSectionContent {
    width: 50%;

    .MaintainSection__title {
      font-size: 40px;
      line-height: 17px;
    }
    p.maintainword {
      text-transform: uppercase;
      color: var(--primaryClr);
      font-weight: 800;
      font-size: 50px;
      position: relative;
      margin-bottom: 70px;
      &::before {
        width: 55px;
        height: 5px;
        display: block;
        content: "";
        position: absolute;
        bottom: -2px;
        left: 30%;
        margin-left: -14px;
        background-color: var(--primaryClr);
      }
      &::after {
        width: 410px;
        height: 1px;
        display: block;
        content: "";
        position: relative;
        margin-top: 6px;
        left: 82px;
        margin-left: -78px;
        background-color: var(--primaryClr);
      }
    }
    p.backsoon {
      font-size: 40px;
      color: #ffffff;
      line-height: 13px;
      font-weight: 600;
      margin-bottom: 30px;
    }
    p {
      font-size: 14px;
    }
  }
  .MaintainImg {
    width: 50%;
    margin: 0 auto;
    text-align: center;
    img {
      width: 500px;
    }
  }
}

.casinoProvidersGames {
  ul {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
    padding: 0 0 15px;
    li {
      background-color: transparent;
      display: block;
      height: auto;
      padding: 0;
      position: relative;
      width: calc(14.285% - 8.57px);
      a {
        display: block;
        display: block;
        color: var(--whiteclr);
        padding: 5px;
        background-color: var(--secondaryClr);
        border-radius: 4px;
        img {
          border-radius: 2px;
          width: 100%;
        }
        &:hover {
          .play-btn {
            inset: 0 0 0 0;
            img {
              height: 60px !important;
              width: 60px !important;
            }
          }
        }
        .play-btn {
          align-items: center;
          background: linear-gradient(
            0deg,
            rgb(39 105 159 / 68%),
            rgb(7 19 28 / 49%)
          );
          border-radius: 4px;
          content: "";
          display: flex;
          inset: 0 0 0 100%;
          justify-content: center;
          position: absolute;
          transition: all 0.9s;
          img {
            border-radius: 10px;
            height: 0;
            width: 0 !important;
          }
        }
      }
    }
  }
}
